<template>
	<div class="share-index-page">
		
		<div class="share-qrcode">
			<van-image :src="share_qrcode"></van-image>
		</div>

		<div class="share-code">
			<p>{{translate('my_invitation_code')}} {{share_code}}</p>
			<p><span class="copy-btn" @click="copy_invite_code">{{translate('click_to_copy')}}</span></p>
		</div>

		<div class="buttons">
			<div class="item icon-link" @click="copy_invite_link">
				<span>{{translate('copy_link')}}</span>
			</div>
			<div class="item icon-download" @click="save_invite_image">
				<span>{{translate('save_picture')}}</span>
			</div>
		</div>

		<van-popup v-model="warningPopup" closeable round  :style="{ width: '280px', height: '120px', padding: '20px' }">
			<br />
			<p style="text-align:center;font-size:24px;">{{warning_title}}</p>
			<br />
			<p style="text-align:center;">{{warning_content}}</p>
		</van-popup>

	</div>
</template>

<script>
	export default {
		name: 'share_index',
		data() {
			return {
				formItem: {
					token: "",
				},

				share_code: "",
				share_qrcode: "",
				commission_total: "",
				subordinate_total: "",	
				invite_link: "",
				alone_link: "",
				
				rules_show: false,

				warningPopup: false,
				warning_title: "",
				warning_content: "",
			}
		},
		mounted() {
			
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			} else {
				this.formItem.token = this.$route.query.token

				this.init()
			}
		},
		methods: {
			init() {
				this.$axios({
					method: 'post',
					url: 'share/index',
					data: this.formItem
				}).then ((res) => {
					if (res.success) {
						this.share_code = res.data.share_code
						this.share_qrcode = res.data.share_qrcode
						this.invite_link = res.data.invite_link
						this.alone_link = res.data.alone_link
						this.commission_total = res.data.commission_total
						this.subordinate_total = res.data.subordinate_total						
	
					}else{
						this.$toast.fail(res.error_text)
					}
				})
			},

			showRulesPopup() {
				this.rules_show = true
			},

			/*   
             * 复制邀请码
             */
			copy_invite_code(){
                if (window.JsObject != null) {
                    window.JsObject.copyInviteCode(this.share_code);
                }

                if (window.webkit != null) {
                    window.webkit.messageHandlers.jsToOcWithPrams.postMessage({"copy_invite_code": this.share_code});
                }
			},

			copy_invite_link(){

                if (window.JsObject != null) {
                    window.JsObject.copyInviteLink(this.invite_link, this.alone_link);
                }

                if (window.webkit != null) {
                    window.webkit.messageHandlers.jsToOcWithPrams.postMessage({"copy_invite_link": this.invite_link, "alone_link": this.alone_link});
                }
			},

			save_invite_image(){
				this.$axios({
					method: 'post',
					url: 'share/image',
					data: this.formItem
				}).then ((res) => {
					if (res.success) {
						console.log(res.data.image)
						
						if (window.JsObject != null) {
							window.JsObject.saveInviteImage(res.data.image);
						}

						if (window.webkit != null) {
							window.webkit.messageHandlers.jsToOcWithPrams.postMessage({"save_invite_image": res.data.image});
						}
					}else{
						return this.translate('failed_to_get_image')
					}
				})
			},

			saveAgent() {
				this.$axios({
					method: 'post',
					url: 'share/distributor',
					data: this.formItem
				}).then ((res) => {
					if (res.success) {
						this.warning_title = this.translate('submitted_successfully')
						this.warning_content = this.translate('contact_you_later')

						this.warningPopup = true

					}else{

						this.warning_title = this.translate('submission_failed')
						this.warning_content = res.error_text

						this.warningPopup = true
					}
				})
			},

			closed() {
				console.log(123)
			},
			
		},
	}
</script>

<style scoped>
.send-code-btn {
	display:inline-block;
	text-align:right;
	color:#FDF773;
	transform: translateY(10px);
}
</style>